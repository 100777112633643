html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body a {
  color: #1a6ab1;
}

.eventcard .card-body,
.eventcard .card-footer {
  padding: 10px !important;

}

.more_info {
  display: block;
  margin-left: 10px;
}

.eventcard {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
  border: none;
  height: 100%;
}

body .font20 {
  font-size: 20px !important;
}

body .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  border: 1px solid;
  padding: 6px 10px;
  border-radius: 5px;
}

.mainEye {
  position: absolute;
  top: 40%;
  right: 20px;
}

.validMainEye {
  position: absolute;
  top: 22%;
  right: 40px;
}

.mainConfirmEye {
  position: absolute;
  top: 40%;
  right: 20px;
}

.validMainConfirmEye {
  position: absolute;
  top: 22%;
  right: 40px;
}

.mtminus {
  margin-top: -4px;
}

.theiaStickySidebar {
  padding: 0px !important;
}

.dashboardview {
  display: flex;
}

.dashboardview .main-content {
  flex: 0 0 auto;
  width: calc(100% - 250px);
  height: calc(100vh - 145px) !important;
  overflow: auto;
}

.dashboardview .profile-sidebar {
  flex: 0 0 auto;
  width: 250px;
  height: calc(100vh - 145px) !important;
  overflow: auto;
}

.header {
  background-color: #fff;
}

.listinfo strong {
  display: block;
}

.listinfo {
  margin-bottom: 20px;
}

.times {
  padding-left: 6px;
}

.text-dark p {
  color: #000 !important;
}
.style_container__1J5TD .ant-space-item span {
  font-size: 15px;
  padding: 8px 14px;
  border-radius: 4px;
  background-color: lightgrey !important;
  color: black;
}