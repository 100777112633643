.button-Join_original {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  outline: 0;
  padding: 7px 20px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-Join_original_disabled {
  background-color: lightgray !important;
  border: 1px solid lightgray !important;
}

.button-Delete {
  background-color: #f83030;
  border: 1px solid #ff0000;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 14px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1a6ab1 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #1a6ab1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #1a6ab1;
}

.card_selected {
  border: 5px solid #1a6ab1 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: #e6e6e6 !important;
  padding: 25px;
}

.btnDashboard {
  background-color: #1a6ab1 !important;
  border: 1px solid #1a6ab1 !important;
  width: 200px;
  color: white !important;
  height: 45px !important;
}

.btnDashboardBook {
  background-color: #1890ff !important;
  border: 1px solid #1890ff !important;
  width: 225px;
  color: white !important;
  height: 60px !important;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.button-Edit-Icon {
  background-color: #1a6ab1;
  border: 1px solid #1a6ab1;
  border-radius: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 12px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 7px 12px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  /* height: 32px; */
  margin: 0;
  padding: 0 20px;
  color: rgb(255 255 255 / 85%);
  font-size: 14px;
  line-height: 30px;
  background: #747474;
  /* border: 1px solid #000000; */
  /* border-top-width: 1.02px; */
  /* border-left-width: 0px; */
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  border-radius: 3px !important;
  /* margin: 0px 12px 0px 0px; */
}

.card_selected_appoiment {
  /* height: 120px; */
  height: 90px;
  border: 2px solid #1a6ab1 !important;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  background-color: white !important;
  padding: 0px !important;
}

.card_static_appiment {
  height: 90px;
  background-color: white !important;
  border: 2px solid #cecece !important;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 3px 5px rgb(0 0 0 / 0.1);
  padding: 0px !important;
}

.div_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-right: 0px !important;
}

.box {
  /* margin-top: 115px !important; */
  /* margin-top: 40px !important; */
  margin-top: 5px;
  /* border-top: 1px solid #c5e5ff; */
  /* border-bottom: 1px solid #c5e5ff; */
  /* background-color: #F9FAFD ; */
  background-color: #134f85 !important;
  height: 70px;
  width: 100% !important;
  color: white;
  padding-top: 20px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.h5New {
  color: white !important;
  font-size: 12px;
}

.h5NewM {
  color: white !important;
  font-size: 12px;
  padding-left: 50px;
}

.pageInfo {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.pageInfoM {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding-left: 50px;
}

.footer {
  position: fixed !important;
  bottom: -20px !important;
  width: 102% !important;
  z-index: 99 !important;
}

.btnBg1 {
  /* width: 200px; */
  border-radius: 5px;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid #1a6ab1 !important;
  color: #1a6ab1 !important;
  height: 45px;
  font-size: 16px;
  background-color: #ffffff !important;
  padding-top: 7px !important;
}

.btnBgred {
  width: 200px;
  border-radius: 5px;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid #ff0000 !important;
  color: #ff0000 !important;
  height: 45px;
  font-size: 16px;
  background-color: #ffffff !important;
  padding-top: 7px !important;
}

.btnBg2 {
  width: 200px;
  border-radius: 5px;
  margin-top: 0px !important;
  border-color: #ffffff !important;
  border: 1px solid #1a6ab1 !important;
  color: #ffffff !important;
  height: 45px;
  font-size: 16px;
  background-color: #1a6ab1 !important;
  padding-top: 7px !important;
}

.btnBg2_disabled {
  width: 200px;
  border-radius: 5px;
  margin-top: 0px !important;
  color: #ffffff !important;
  height: 45px;
  font-size: 16px;
  pointer-events: none;
  padding-top: 7px !important;
}

/* ---- */

.menu-bars {
  background: white;
}

.nav-menu {
  background-color: white;
  width: 370px;
  height: 16vh;
  display: flex;
  justify-content: center;
  position: fixed;
  flex-direction: column;
  top: 415px;
  left: -100%;
  transition: 850ms;
  z-index: 99999;
}

.badge {
  line-height: 1.5 !important;
  line-height: 1.5 !important;
  padding: 1px 7px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.lineSearchDoctor {
  margin-top: 0px !important;
  padding: 20px 0px 0px 20px;
  /* background-color: #3790ff21; */
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

input {
  box-shadow: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #1a6ab1 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: #1a6ab1 !important;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: smaller;
}

.selectCardServices {
  border-color: #009efb;
  border-width: 1;
}

.btn-lg,
.btn-group-lg>.btn {
  font-size: 16px;
}

.react-tel-input .form-control {
  width: 100%;
}

.div_flex_HelthCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.div_flex_HelthCard_Center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container input {
  height: 35px;
}


.profpic {
  width: 100px;
  border-radius: 100%;
  object-fit: cover;
  height: 100px;
}

.profpic_D {
  width: 80px;
  border-radius: 10%;
  object-fit: cover;
}

.fontColor {
  color: #134f85;
  font-size: 14px;
  font-weight: bold;
}

.editIcon {
  height: 14px;
  margin: 5px;
  margin-right: 10px;
}

.search-emoji {
  display: none;
}

.selected-flag {
  display: none;
}

.flag-dropdown {
  display: none;
}

.react-tel-input .form-control {
  padding-left: 10px;
}

.speciality {
  width: 301px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-title {
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-radio-wrapper {
  align-items: flex-start !important;
}

.ant-popover-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

}

.ant-steps-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 600px) {
  .tab-view .nav-item {
    width: 40% !important;
  }

  .smallDivices {
    flex-wrap: unset !important
  }

  .btnBg1 {
    width: 100px !important;
  }

  .btnBgred {
    width: 100px !important;
  }

  .heightDiv {
    height: 65vh !important;
  }
}

.phone_num {
  height: 20px !important;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .heightDiv {
    height: 65vh !important;
  }
}

.style_leftBox__2TltT {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* .ant-radio-button-wrapper{
  background-color: white;
  color: gray;
} */

/* .tab-view .nav-item.active{
  background: rgb(255, 255, 255);
    border: 1px solid white;
    border-bottom: 1px solid white !important;
    border-radius: 10px;
    columns: white !important;
    height: 35px;
    padding: 7px;
    margin-top: 10px;
}
.tab-view .nav-item{
  background: rgb(255, 255, 255);
    height: 35px;
    padding: 7px;
    margin-top: 10px;
} */

.tab_selected {
  background-color: rgb(255, 255, 255);
  padding: 6px;
  border-radius: 5px;
  text-align: center;
}

.tab_selected p {
  color: #009efb !important;
  font-weight: bold;
  font-size: 12px;
}

.tab_static {
  padding: 6px;
  padding-left: 15px;
  text-align: center;

}

.active_sidebar {
  background-color: #194f85;
  color: white;
}

.active_sidebar span {
  color: white !important;
}

.ant-drawer-body {
  overflow: hidden !important;
}

/* .ant-modal-close-x {
  display: contents;
  width: 56px;
  height: 56px;
  font-size: 24px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
} */

@media only screen and (max-width: 832px) {
  .ant-steps {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 832px) {
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0px !important;
    margin-top: 10px !important;
  }
}


p .btn {
  text-decoration: none !important;
}

p .btn:hover {
  text-decoration: underline !important;
}


.newAllModalCss .ant-modal-content{
  box-shadow : unset !important;
}